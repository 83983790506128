import axios from "axios";
import { create_UUID } from "utils/uuid";

export const authHeader = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  let headers = {
    "Content-Type": "application/json",
    Accept: "*/*"
  };
  if (token) {
    const tokenHeader = { authorization: `Bearer ${token}` };
    headers = {
      ...headers,
      ...tokenHeader,
    };
  }
  return headers;
};

export const GET = async (url, headers) => {
  const response = await axios.get(url, { headers });
  return response;
};

export const POST = async (apiUrl, body, header) => {
  let uuid = await create_UUID();
  let newBody = { ...body, requestId: uuid };
  const response = await axios({
    method: "POST",
    url: apiUrl,
    headers: header,
    data: newBody,
  });
  return response;
};

export const POST_NO_REQ = async (apiUrl, body, header) => {
  let uuid = await create_UUID();
  let newBody = { ...body};
  const response = await axios({
    method: "POST",
    url: apiUrl,
    headers: header,
    data: newBody,
  });
  return response;
}

export const POST_FILE = async (apiUrl, body, header) => {
  let uuid = await create_UUID();
  body.append('requestId', uuid);
  const res = await fetch(apiUrl, {
    method: "POST",
    body: body,
    headers: header,
  });
  return res.json();
};

export const PUT = async (apiUrl, body, header) => {
  const response = await axios({
    method: "PUT",
    url: apiUrl,
    headers: header,
    data: body,
  });
  return response;
};

export const DELETE = async (apiUrl, header) => {
  const response = await axios({
    method: "DELETE",
    url: apiUrl,
    headers: header,
  });
  return response;
};
